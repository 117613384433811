import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Confirmation } from "page_components/contact"

const ContactConfirmation = () => {
  const title = "Dziękujemy"

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Confirmation />
    </Layout>
  )
}

export default ContactConfirmation
