import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Confirmation = () => {
  return (
    <section className="contact-confirmation">
      <div className="container-fluid">
        <h3>Twoja wiadomość została wysłana!</h3>
        <p>
          Nasi specjaliści już otrzymali Twoją wiadomość. Skontaktują się z Tobą
          najszybciej jak to będzie możliwe, aby udzielić Ci wyczerpujących
          informacji na Twoje pytania.
        </p>
        <Button to="/">Strona główna</Button>
      </div>
    </section>
  )
}

export default Confirmation
